import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box } from "@makerdao/ui-components-core";
import downCaret from "./assets/downCaret.inline.svg";

export const MobileHeader = styled(Flex)`
  color: ${props => props.theme.header.linkHeaderColor};
  cursor: pointer;
  font-size: 2.85rem;
  padding-bottom: 0.7rem;
  justify-content: space-between;
  align-items: center;
`;

const MobileDropdownHeader = styled(MobileHeader)`
  &:after {
    transition: transform 0.2s;
    content: "";
    mask: url(${downCaret}) center center no-repeat;
    background-color: ${props => props.theme.header.linkHeaderColor};
    width: 2.8rem;
    height: 1.6rem;
    margin-right: 0.6rem;
    ${props => props.open && `transform: rotate(180deg);`}
  }
`;

const MobileDropdownItemsContainer = styled.div`
  opacity: 0;
  height: auto;
  max-height: 0px;
  transform-origin: top;
  transition: all 0.25s ease-in-out;
  pointer-events: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 23px;
  ${props =>
    props.open &&
    `
    pointer-events: unset;
    max-height: 500px;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 20px
  `}
`;

class MobileHeaderDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggleDropdown() {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    return (
      <Box pt="1.4rem" pb="1rem">
        <MobileDropdownHeader
          open={this.state.open}
          onClick={this.toggleDropdown.bind(this)}
        >
          {this.props.title}
        </MobileDropdownHeader>
        <MobileDropdownItemsContainer open={this.state.open}>
          {this.props.children}
        </MobileDropdownItemsContainer>
      </Box>
    );
  }
}

MobileHeaderDropdown.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default MobileHeaderDropdown;
