import React from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { IntlProvider } from 'react-intl'
import { string } from 'prop-types'

import { themeShared, Box } from '@makerdao/ui-components-core'
import Header from './header'
import Footer from './footer'
import CookieNotice from './cookieNotice'
import '../styles/minireset.styl'
import '../styles/index.styl'

import { defaultLocale, getMessages, getMessage } from '../i18n'
import SocialTags from './socialTags'

export const adjustedTheme = {
  ...themeShared,
  header: {
    mobileMenuBackground: 'white',
    linkHeaderColor: 'black',
    linkColor: 'black',
  },
  footer: {
    color: 'black',
    backgroundColor: 'transparent',
    listHeaderColor: 'black',
    linkColor: '#333333',
    linkHoverColor: themeShared.colors.teal['500'],
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.bodyBackgroundColor};
  }
`

class Layout extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line
    window.localStorage.setItem(
      'locale_language',
      this.props.locale || defaultLocale
    )
  }

  render() {
    const {
      children,
      themeOverrides,
      hideFooter,
      fixedMobileMenu,
      locale,
      bodyBackgroundColor,
      meta,
      ...otherProps
    } = this.props
    const { title, description } = meta || {}
    const defaultTitle = 'Maker'
    const defaultDescription = getMessage('meta.description', locale)
    const metaImgUrl = 'https://makerdao.com/dai.png'

    return (
      <ThemeProvider
        theme={Object.assign({}, adjustedTheme, themeOverrides || {})}
      >
        <IntlProvider
          locale={locale || defaultLocale}
          messages={getMessages(locale || defaultLocale)}
        >
          <div>
            <Helmet
              meta={[
                {
                  name: 'keywords',
                  content: 'makerdao, dai, blockchain, dapp',
                },
              ]}
              link={[
                {
                  rel: 'shortcut icon',
                  type: 'image/png',
                  href: '/favicon.png',
                },
              ]}
            >
              {[
                'FTBase-Regular.woff2',
                'FTBase-Regular.woff',
                'FTBase-Medium.woff2',
                'FTBase-Medium.woff',
                'FTBase-Bold.woff2',
                'FTBase-Bold.woff',
              ].map(fontFileName => (
                <link
                  rel="preload"
                  as="font"
                  href={`/fonts/${fontFileName}`}
                  type={`font/${fontFileName.split('.')[1]}`}
                  crossOrigin="anonymous"
                />
              ))}
              {[57, 60, 72, 76, 114, 120, 144, 152, 180].map(size => (
                <link
                  rel="apple-touch-icon"
                  sizes={`${size}x${size}`}
                  href={`/apple-icon-${size}x${size}.png`}
                />
              ))}
              <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/android-icon-192x192.png"
              />
              {[16, 32, 96].map(size => (
                <link
                  rel="icon"
                  type="image/png"
                  sizes={`${size}x${size}`}
                  href={`/favicon-${size}x${size}.png`}
                />
              ))}
              <link rel="manifest" href="/manifest.json" />
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta
                name="msapplication-TileImage"
                content="/ms-icon-144x144.png"
              />
              <meta name="theme-color" content="#ffffff" />
            </Helmet>
            <SocialTags
              pageTitle={title || defaultTitle}
              description={description || defaultDescription}
              metaImgUrl={metaImgUrl}
            />
            <Box zIndex="10" position="relative" pt="20px">
              <Header
                locale={locale}
                fixedOnMobile={fixedMobileMenu}
                announcement={
                  <span>
                    MakerDAO is now Sky — the next evolution of DeFi. Explore <a href="https://sky.money">Sky.money</a> and get rewarded for saving without giving up control.
                  </span>
                }
                />
            </Box>
            <GlobalStyle bodyBackgroundColor={bodyBackgroundColor || 'white'} />
            <Box mx="auto" mb="1rem" {...otherProps}>
              {children}
              <CookieNotice />
            </Box>
            {hideFooter ? '' : <Footer locale={locale} />}
          </div>
        </IntlProvider>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  locale: string.isRequired,
}

export default Layout
