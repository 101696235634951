import blogLangs from './i18n/blog-langs'

const urls = [
  {
    name: 'Blog',
    i18nUrl: locale =>
      'https://blog.makerdao.com/' +
      (blogLangs[locale] && blogLangs[locale] !== 'en'
        ? blogLangs[locale]
        : ''),
  },
  {
    name: 'CDP Portal',
    i18nUrl: locale => {
      const subdomain = locale === 'zh-CN' ? 'cdp-cn' : 'cdp'
      return `https://${subdomain}.makerdao.com`
    },
  },
  {
    name: 'Chat',
    url: 'https://chat.makerdao.com',
  },
  {
    name: 'Community',
    url: '/community',
  },
  {
    name: 'Contact',
    url: '/contact',
  },
  {
    name: 'Dai.js Docs',
    url:
      'https://docs.makerdao.com/building-on-top-of-the-maker-protocol/dai.js-wiki',
  },
  {
    name: 'Developer Guides',
    url: 'https://github.com/makerdao/developerguides',
  },
  {
    name: 'Documentation',
    url: 'http://docs.makerdao.com/',
  },
  {
    name: 'Forum',
    url: 'https://forum.makerdao.com',
  },
  {
    name: 'Github',
    url: 'https://github.com/makerdao',
  },
  {
    name: 'Governance',
    url: '/governance',
  },
  {
    name: 'Job Opportunities',
    url: 'https://forum.makerdao.com/c/miscellaneous/job-opportunities/11',
  },
  {
    name: 'Vote',
    url: 'https://vote.makerdao.com',
  },
  {
    name: 'Bug Bounty',
    url: 'https://immunefi.com/bounty/makerdao/',
  },
  {
    name: 'Migrate',
    url: 'https://migrate.makerdao.com',
  },
  {
    name: 'Oasis Trade',
    url: 'https://oasis.app/trade',
  },
  {
    name: 'Oasis Save',
    url: 'https://oasis.app/save',
  },
  {
    name: 'Oasis',
    url: 'https://oasis.app',
  },
  {
    name: 'Privacy Policy',
    url: '/privacy',
  },
  {
    name: 'Reddit',
    url: 'https://www.reddit.com/r/MakerDAO/',
  },
  {
    name: 'Service Status',
    url: 'https://makerdao.statuspage.io/',
  },
  {
    name: 'About',
    url: '/about',
  },
  {
    name: 'Telegram',
    url: 'https://t.me/makerdaoOfficial',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/SkyEcosystem',
  },
  {
    name: 'WeChat',
    url: 'http://weixin.qq.com/r/tjh0bADEN11IrUQP922k',
  },
  {
    name: 'Whitepaper',
    url: '/whitepaper',
  },
  {
    name: 'Youtube',
    url: 'https://www.youtube.com/MakerDAO',
  },
]

function getUrls(locale) {
  return name => {
    const url = urls.find(link => link.name === name)
    if (!url) {
      throw new Error('URL not found "' + name + '"')
    }
    if (url.i18nUrl && url.url) {
      throw new Error("Url objects can't have both url and i18nUrl")
    }
    return url.i18nUrl ? url.i18nUrl(locale) : url.url
  }
}

export default getUrls
