import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { QRCode } from "react-qr-svg";
import {
  Flex,
  Grid,
  Box,
  Input,
  Modal,
  Button,
} from "@makerdao/ui-components-core";

import TwitterIcon from "./assets/twitterIcon.svg";
import RedditIcon from "./assets/redditIcon.svg";
import YoutubeIcon from "./assets/youtubeIcon.svg";
import TelegramIcon from "./assets/telegramIcon.svg";
import RocketChatIcon from "./assets/rocketChatIcon.svg";
import WeChatIcon from "./assets/wechatIcon.svg";
import Arrow from "./assets/arrow.svg";

import { getMessage, languages } from "../../i18n";
import Link from "../link"
import getUrls from "../../urls"
import LangDropdown from "../langDropdown"

const FooterContainer = styled.footer`
  font-size: 1.5rem;
  padding: 1rem 2.5rem;
  width: 100%;
  background-color: ${props => props.theme.footer.backgroundColor};
  
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    padding: 6rem 2.5rem;
  }
`;

const LinkListHeader = styled.div`
  font-weight: 500;
  margin-bottom: 0.7rem;
  color: ${props => props.theme.footer.listHeaderColor};
`;

const LinkList = styled.ul`
  padding-left: 0;
  list-style: none;
  line-height: 3.1rem;

  & a {
    color: ${props => props.theme.footer.linkColor};
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
  }

  & a:hover {
    color: ${props => props.theme.footer.linkHoverColor};
  }
`;

const SubscribeButton = styled.button`
  border: none;
  font-size: 1em;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 44px;
  color: #9898a6;
  transition: color 0.125s;
  cursor: pointer;
  display: inline;
  padding-right: 1rem;

  border-left: 1px solid #d4d9e1;

  &:hover svg,
  &:focus svg {
    transform: translate(0.25rem);
  }

  &:hover,
  &:focus {
    outline: none;
    color: #53546a;
  }

  input:focus + & {
    color: #53546a;

    &:after {
      background-color: #53546a;
    }
  }

  & svg {
    margin: 0 auto;
    transition: transform 0.125s;
  }
`;

const Icon = styled(Box)`
  & svg {
    width: 20px;
    height: 20px;
    fill: #333;
    transition: fill 0.2s;
    cursor: pointer;

    &:hover {
      fill: ${props => props.color};
    }
  }
`;

// eslint-disable-next-line
const rfc2822EmailRegex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;

const LangDropdownWrapper = styled.div`
  :hover {
    .trigger {
      background: #f3f3f3;
    }
  }
`

/**
 *Global footer component.
 */
class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      message: "",
      subscribeState: "",
      showWeChatModal: false,
    };

    this.subscribeEmail = this.subscribeEmail.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.toggleWeChatModal = this.toggleWeChatModal.bind(this);
  }

  updateEmail(evt) {
    this.setState({
      email: evt.target.value,
      message: "",
      subscribeState: "",
    });
  }

  subscribeEmail() {
    const httpRequest = new XMLHttpRequest();

    if (!rfc2822EmailRegex.test(this.state.email)) {
      this.setState({
        message: "Please enter a valid email address.",
        subscribeState: "failure",
      });
      return;
    }

    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        if (httpRequest.status === 200) {
          this.setState({
            email: "",
            message: `Thank you. You'll hear from us soon.`,
            subscribeState: "success",
          });
        } else {
          this.setState({
            message: "An error occurred. Please try again later.",
            subscribeState: "failure",
          });
        }
      }
    };

    httpRequest.onerror = () => {
      this.setState({
        message: "An error occurred. Please try again later.",
        subscribeState: "failure",
      });
    };

    httpRequest.open(
      "POST",
      "https://578v7re7h2.execute-api.us-east-2.amazonaws.com/default/addContactToMailingList",
      true
    );
    httpRequest.setRequestHeader("Content-Type", "application/json");

    httpRequest.send(
      JSON.stringify({
        email: this.state.email,
      })
    );
  }

  toggleWeChatModal() {
    this.setState({
      showWeChatModal: !this.state.showWeChatModal,
    });
  }

  render() {
    const urls = getUrls(this.props.locale)
    const t = text => (getMessage('menu-' + text, this.props.locale, text) || text);

    return (
      <FooterContainer>
        <Grid
          maxWidth="1140px"
          m="0 auto"
          gridTemplateColumns={[
            "1fr",
            "1fr 1fr",
            "1fr 1fr",
            "repeat(4, 1fr) auto",
          ]}
          gridRowGap={["4rem", "2rem"]}
        >
          <div>
            <LinkListHeader>{t("Resources")}</LinkListHeader>
            <LinkList>
              <li>
                <Link to={urls('Whitepaper')}>
                  {t("Whitepaper")}
                </Link>
              </li>
              <li>
                <Link to={urls('Service Status')}>
                  {t("Service Status")}
                </Link>
              </li>
            </LinkList>
          </div>
          <div>
            <LinkListHeader>{t("Products")}</LinkListHeader>
            <LinkList>
              <li>
                <Link to={urls('Migrate')}>{t("Migrate")}</Link>
              </li>
              <li>
                <Link to={urls('Governance')}>{t("Governance")}</Link>
              </li>
            </LinkList>
          </div>
          <div>
            <LinkListHeader>{t("Developers")}</LinkListHeader>
            <LinkList>
              <li>
                <Link to={urls('Documentation')} external="true">
                  {t("Documentation")}
                </Link>
              </li>
              <li>
                <Link to={urls('Dai.js Docs')}>Dai.js</Link>
              </li>
              <li>
                <Link to={urls('Developer Guides')}>
                  {t("Developer Guides")}
                </Link>
              </li>
              <li>
                <Link to={urls('Bug Bounty')}>
                  {t("Bug Bounty")}
                </Link>
              </li>
            </LinkList>
          </div>
          <div>
            <LinkListHeader>{t("Foundation")}</LinkListHeader>
            <LinkList>
              <li>
                <Link to={urls('Contact')}>{t("Contact")}</Link>
              </li>
              <li>
                <Link to={urls('Job Opportunities')}>{t("Job Opportunities")}</Link>
              </li>
            </LinkList>
          </div>
          <Box gridColumn={["1 / 3", "1 / 3", "unset"]}>
            <Box mt="0.4rem" className={this.state.subscribeState}>
              <Input
                maxWidth="320px"
                py="9px"
                position="relative"
                placeholder={t("sign-up-text-placeholder")}
                name="email"
                type="email"
                value={this.state.email}
                onChange={evt => this.updateEmail(evt)}
                errorMessage={
                  (this.state.subscribeState === "failure" || undefined) &&
                  this.state.message
                }
                successMessage={
                  (this.state.subscribeState === "success" || undefined) &&
                  this.state.message
                }
                after={
                  <SubscribeButton type="submit" onClick={this.subscribeEmail}>
                    <Arrow />
                  </SubscribeButton>
                }
              />
            </Box>
            <Grid
              gridColumnGap="24px"
              mt="2.1rem"
              gridTemplateColumns="repeat(7, 20px)"
            >
              <Link to={urls('Twitter')}>
                <Icon color="#4AA1EC">
                  <TwitterIcon />
                </Icon>
              </Link>
              <Link to={urls('Reddit')}>
                 <Icon color="#EC5428">
                <RedditIcon />
                </Icon>
              </Link>
              <Link to={urls('Telegram')}>
                 <Icon color="#29A0D3">
                <TelegramIcon />
                </Icon>
              </Link>
              <Link to={urls('Chat')}>
                 <Icon color="#C5362E">
                <RocketChatIcon />
                </Icon>
              </Link>
               <Icon color="#58C042">
              <WeChatIcon
                style={{ cursor: "pointer" }}
                onClick={this.toggleWeChatModal}
              />
              </Icon>
              <Link to={urls('Youtube')}>
                 <Icon color="#EB3223">
                <YoutubeIcon />
                </Icon>
              </Link>
            </Grid>
            <Box mt="2.1rem" maxWidth="180px">
              <LangDropdownWrapper>
                <LangDropdown languages={languages.filter(lang => !lang.hideOnFooter)} selected={this.props.locale} />
              </LangDropdownWrapper>
            </Box>
          </Box>
        </Grid>
        <Modal
          show={this.state.showWeChatModal}
          onClose={this.toggleWeChatModal}
          width="380px"
        >
          <Flex flexDirection="column" alignItems="center">
            <QRCode
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              style={{ width: "245px", height: "245px" }}
              value={urls('WeChat')}
            />
            <Box fontSize="1.8rem">{t("wechat-scan-text")}</Box>
            <Button mt="m" onClick={this.toggleWeChatModal}>
              Close
            </Button>
          </Flex>
        </Modal>
      </FooterContainer>
    );
  }
}

Footer.propTypes = {
  locale: PropTypes.string,
  langDropdown: PropTypes.element,
};

export default Footer;
