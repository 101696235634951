import React from 'react'
import styled from 'styled-components'
import Globe from '../images/imported/icons/globe.svg'

import { Dropdown, DefaultDropdown, Flex } from '@makerdao/ui-components-core'
import { arrayOf, shape, string } from 'prop-types'
import parse from 'url-parse'
import { navigate } from 'gatsby'
import { transition } from './css-mixins'
import { languages as allLanguages } from '../i18n'

const DefaultTriggerStyle = styled(Flex)`
  align-items: center;
  border-radius: 6px;
  background: transparent;
  ${transition};
  padding-right: 11px;
  padding-left: 8px;
  height: 33px;

  svg {
    margin-right: 8px;
  }

  .text {
    padding-bottom: 2px;
    letter-spacing: 0.5px;
    color: #000;
  }
`

const DefaultTrigger = ({ children, ...props }) => (
  <DefaultTriggerStyle {...props}>
    <Globe />
    <div className="text">{children}</div>
  </DefaultTriggerStyle>
)

const Item = styled.div`
  color: ${props => props.theme.footer.linkColor};
  line-height: 2.4rem;
  transition: color 0.1s ease;
  text-decoration: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    color: #1aab9b;
  }
`

const LangDropdown = ({
  languages,
  intl,
  defaultText,
  Trigger,
  DropdownItems,
  selected,
  ...props
}) => {
  const langText = allLanguages.find(l => l.value === selected).text
  const TriggerUsed = Trigger || DefaultTrigger
  const DropdownUsed = DropdownItems || DefaultDropdown

  function handleSelect(newSelectedValue) {
    if (selected === newSelectedValue) {
      return
    }
    const { pathname } = parse(window.location.href)
    const splitPath = pathname.split('/')
    const langInUrl = splitPath[1]
    const originalPath = pathname.slice(langInUrl.length + 1)

    localStorage.setItem('locale_language', newSelectedValue)
    navigate(`/${newSelectedValue}${originalPath}`)
  }

  return (
    <Dropdown
      trigger={
        <TriggerUsed className="trigger">{langText || defaultText}</TriggerUsed>
      }
      placement="bottom-start"
      hitBoxMargin="10px"
      offset="0, 10px"
      {...props}
    >
      <DropdownUsed>
        {languages
          .filter(lang => lang.value !== selected)
          .map(({ value, text }) => (
            <Item key={value} onClick={() => handleSelect(value)}>
              {text}
            </Item>
          ))}
      </DropdownUsed>
    </Dropdown>
  )
}

LangDropdown.propTypes = {
  /** Array of languages in the form {value, text}, where value is the locale, and text is the displayed language. */
  languages: arrayOf(
    shape({
      value: string.isRequired,
      text: string.isRequired,
    })
  ).isRequired,

  /** The currently selected locale **/
  selected: string,

  /** The text to display when no language is selected */
  defaultText: string,
}

LangDropdown.defaultProps = {
  defaultText: 'Select',
}

export default LangDropdown
