import React from 'react'
import PropTypes from 'prop-types'
import { Link as InternalLink } from '../i18n'

class Link extends React.Component {
  render() {
    const { to, ...rest } = this.props
    if (!to) {
      return <span {...rest}>{this.props.children}</span>
    }
    return to.startsWith('http') || to.startsWith('mailto:') ? (
      <a href={to} rel="noopener noreferrer" target="_blank" {...rest}>
        {this.props.children}
      </a>
    ) : (
      <InternalLink to={to} {...rest}>
        {this.props.children}
      </InternalLink>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string,
}

export default Link
