import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Hamburger from "./Hamburger"
import MobileHeaderDropdown, {MobileHeader} from "./MobileHeaderDropdown"

import { Box, DefaultDropdown, Dropdown } from "@makerdao/ui-components-core"
import MakerLogo from "./assets/makerLogo.svg"

import { getMessage } from "../../i18n"
import Link from "../link"
import getUrls from "../../urls"

const HopOnHover = styled(Box)`
  transition: all .15s ease;
  
  :hover {
   transform: translateY(-2px);
   -webkit-transform: translateY(-2px);
  }
`

const Trigger = styled.span`
  font-weight: normal;
  cursor: pointer;
  color: ${props => props.theme.header.linkHeaderColor};
  font-size: 1.8rem;
  
  a {
    text-decoration: none;
  }
`;

const DesktopMenu = styled(Box)`
  .menu-item {
    transition: opacity 0.2s ease 0s;
  }
  &:hover {
    .menu-item {
      opacity: 0.33;
      &:hover {
        opacity: 1.0;
      }
    }
  }
`

const StyledDropdown = styled(DefaultDropdown)`
  border-color: rgb(226, 226, 226); 
  border-radius: 8px;
  padding: 8px 50px 8px 22px;
  
  .subheading{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #707070;
    margin-top: 4px;
    margin-bottom: 17px;
  }
`

const HeaderNormal = styled(Box)`
  max-width: 1250px;
  margin: 0 auto;
  justify-content: space-between; 
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  padding: 2rem 2.5rem 1.4rem 2.5rem;
`

const HeaderFixed = styled(HeaderNormal)`
  padding: 1.8rem 2.8rem 1.6rem 2.4rem;
  border-bottom: 1px solid #d4d4d4;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
`

const MobileMenu = styled(Box)`
  background-color: ${props =>
    props.theme.header.mobileMenuBackground ||
    props.theme.header.backgroundColor};
  overflow-y: scroll;
  transition: all 0.2s ease-in-out;
`;

const HeaderDropdownLink = styled(Link)`
  color: ${props => props.theme.header.linkColor};
  text-decoration: none;
  font-size: 1.7rem;
  cursor: pointer;
`;

const MobileHeaderDropdownLink = styled(Link)`
  line-height: 4.2rem;
  color: #000;
  font-size: 2.1rem;
  text-decoration: none;
`

// A little space on the top that needs to be covered in white
// todo: come up with a more elegant solution
const MobileWhiteTopCover = styled(Box)`
  position: fixed;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  transition: background 0.2s ease-in-out;
`

// Sticky Banner Component
const StickyBanner = styled(Box)`
  background-color: #b30000;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  a {
    color: white;
    text-decoration: underline;
  }
`

/**
 * Global header component.
 */
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMenuOpen: false,
    };
  }

  toggleMenu() {
    if (!this.state.mobileMenuOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
    }

    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  }

  componentWillUnmount() {
    document.body.style.overflow = "unset";
    document.body.style.height = "unset";
  }

  render() {
    const t = text => (getMessage('menu-' + text, this.props.locale, text) || text);

    const rh = ({mobile, desktop}) => [mobile, mobile, desktop]
    const urls = getUrls(this.props.locale)
    const fixedOnMobile = this.props.fixedOnMobile

    const menuItems = [
      {
        title: t("Developers"),
        items: [
          {
            text: t("Documentation"),
            to: urls('Documentation'),
          },
          { text: t("Dai.js"), to: urls('Dai.js Docs') },
          { text: t("Developer Guides"), to: urls('Developer Guides') },
          { text: t("Bug Bounty"), to: urls('Bug Bounty') },
        ],
      },
      {
        title: t("Learn"),
        items: [
          { text: t("Whitepaper"), to: urls('Whitepaper') },
        ],
      },
      {
        title: t("Foundation"),
        items: [
          { text: t("Contact"), to: urls('Contact') },
          { text: t("Job Opportunities"), to: urls('Job Opportunities') },
        ],
      },
      {
        title: t("Community"),
        headerUrl: urls('Community'),
        items: [
          { text: t("Blog"), to: urls('Blog') },
          { text: t("Chat"), to: urls('Chat') },
          { text: t("Forum"), to: urls('Forum') },
        ],
      },
      {
        title: t("Vote"),
        headerUrl: urls("Vote"),
      }
    ];

    return (
      <Box width="100%" position="relative">
        {this.props.announcement && (
          <StickyBanner>
            {this.props.announcement}
          </StickyBanner>
        )}
        <HeaderNormal
          display={rh({
            mobile: fixedOnMobile ? "none" : "flex",
            desktop: "flex"
          })}
          background={this.state.mobileMenuOpen ? "#fff" : "rgba(255,255,255,0)"}
          style={{ marginTop: this.props.announcement ? '50px' : '0' }}
        >
          <MobileWhiteTopCover
            display={rh({
              mobile: "block",
              desktop: this.state.mobileMenuOpen ? 'block' : 'none'
            })}
            background={this.state.mobileMenuOpen ? '#fff' : 'rgba(255,255,255,0)'}
          />
          <HopOnHover>
            <Link to="/">
              <MakerLogo />
            </Link>
          </HopOnHover>
          <DesktopMenu display={this.state.mobileMenuOpen ? "none" : "flex"}>
            {menuItems.map(item => (
              <Box
                ml="70px"
                key={item.title}
                display={rh({
                  mobile: "none",
                  desktop: "block"
                })}
                className="menu-item"
              >
                {
                  item.items ?
                    <Dropdown trigger={item.headerUrl ?
                      <Trigger><Link to={item.headerUrl}>{item.title}</Link></Trigger> :
                      <Trigger>{item.title}</Trigger>
                    } offset="0, 6px">
                      <StyledDropdown>
                        {item.items && item.items.map((subitem, index) => (
                          <div key={index}>
                            <HeaderDropdownLink
                              to={subitem.to}
                              external={subitem.external}
                            >
                              <Box style={{lineHeight: subitem.subheading ? '2.4rem' : '3.2rem'}}>
                                {subitem.text}
                                {subitem.subheading ?
                                  <Box className="subheading">{subitem.subheading}</Box>
                                  : null
                                }
                              </Box>
                            </HeaderDropdownLink>
                          </div>
                        ))}
                      </StyledDropdown>
                    </Dropdown> :
                    <Trigger><Link to={item.headerUrl}>{item.title}</Link></Trigger>
                }

              </Box>
            ))}
          </DesktopMenu>
          <Box
            p="25px"
            m="-25px"
            onClick={this.toggleMenu.bind(this)}
            display={rh({
              mobile: "block",
              desktop: this.state.mobileMenuOpen ? "block" : "none"
            })}
          >
            <Hamburger active={this.state.mobileMenuOpen} />
          </Box>
        </HeaderNormal>
        <HeaderFixed
          display={rh({
            mobile: fixedOnMobile ? "flex" : "none",
            desktop: fixedOnMobile && this.state.mobileMenuOpen ? "flex" : "none"
          })}
        >
          <Link to="/">
            <MakerLogo width="32px" />
          </Link>
          <Box
            p="25px"
            m="-25px"
            onClick={this.toggleMenu.bind(this)}
            display={rh({
              mobile: "block",
              desktop: this.state.mobileMenuOpen ? "block" : "none"
            })}
          >
            <Hamburger active={this.state.mobileMenuOpen} />
          </Box>
        </HeaderFixed>
        <MobileMenu
          width="100vw"
          opacity={this.state.mobileMenuOpen ? 1 : 0}
          height={this.state.mobileMenuOpen ? "100%" : "0"}
          position="fixed"
          left="0"
          zIndex="99"
          style={{top: fixedOnMobile ? '61px' : null}}
        >
          <Box p="2.3rem 2.5rem 10.3rem 2.5rem" m="0 auto" maxWidth="1140px">
            {menuItems.map(item => (
              item.items ?
                <MobileHeaderDropdown title={item.title} key={item.title}>
                  {item.items && item.items.map((subitem, index) => (
                    <div key={index} onClick={this.toggleMenu.bind(this)}>
                      <MobileHeaderDropdownLink to={subitem.to} external={subitem.external}>
                        {subitem.text}
                      </MobileHeaderDropdownLink>
                    </div>
                  ))}
                </MobileHeaderDropdown> :
                <Box pt="1.4rem" pb="1rem">
                  <Link to={item.headerUrl} style={{textDecoration: 'none'}}>
                    <MobileHeader>
                      {item.title}
                    </MobileHeader>
                  </Link>
                </Box>
            ))}
          </Box>
        </MobileMenu>
      </Box>
    );
  }
}

Header.propTypes = {
  locale: PropTypes.string,
  announcement: PropTypes.node, // Prop for the announcement text
};

export default Header;
