import { css } from 'styled-components'

export const flexCenterContent = css`
  justify-content: center;
  align-items: center;
`

export const absoluteLayer = css`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const transition = css`
  transition: all 0.1s ease 0s;
`
