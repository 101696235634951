import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Cross from '../images/imported/icons/crossIcon2.svg'
import { Box, Flex } from '@makerdao/ui-components-core'
import Link from '../i18n/i18nLink'
import Cookies from 'js-cookie'
import { FormattedMessage as FM } from 'react-intl'

const CookieNoticeStyle = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(196, 196, 196, 0.25);
  border-radius: 55px;

  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 3000;
  padding: 8px 15px 12px 23px;
  white-space: nowrap;

  @media (max-width: 435px) {
    bottom: 0;
    border-radius: unset;
    white-space: normal;
    width: 100%;
  }
`

const StyledCross = styled(Cross)`
  opacity: 0.3;
  cursor: pointer;
  flex-shrink: 0;
  transition: opacity 0.1s ease 0s;
  margin-left: 14px;
  :hover {
    opacity: 1;
  }
`

const CookieNotice = () => {
  const MAKER_PRIVACY_ACCEPTED_DATE = 'maker_privacy_accepted_date'
  const [show, setShow] = useState(false)

  useEffect(() => {
    const acceptedDate = Cookies.get(MAKER_PRIVACY_ACCEPTED_DATE)
    if (!acceptedDate) {
      setShow(true)
    }
  })

  const handleClose = () => {
    Cookies.set(MAKER_PRIVACY_ACCEPTED_DATE, new Date().toISOString(), {
      expires: 365,
    })
    setShow(false)
  }

  return (
    <CookieNoticeStyle style={{ display: show ? 'inline-block' : 'none' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Box fontSize="15px" color="black">
          <FM
            id="cookie-notice"
            values={{
              '0': (
                <Link to="/privacy/#use-of-cookies-and-similar-technologies">
                  <FM id="Cookie Policy" />
                </Link>
              ),
            }}
          />
        </Box>
        <StyledCross onClick={handleClose} />
      </Flex>
    </CookieNoticeStyle>
  )
}

export default CookieNotice
